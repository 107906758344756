import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenfranco/Documents/dev/medgrocer/pp-jn-website/src/components/Layout/Layout.js";
import { graphql, navigate, Link } from "gatsby";
import classNames from "classnames";
import Img from "gatsby-image";
import Hero from "../components/Layout/Hero";
import HeroBanner from "../components/Elements/HeroBanner";
import MediaBox from "../components/Elements/MediaBox";
import SEO from "components/Layout/SEO";
import clinics from "../../static/images/icons/icons__analytics--colored.png";
import employeeBenefits from "../../static/images/icons/icons__vaccination--colored.png";
import patientSupport from "../../static/images/icons/icons__pharmacy--colored.png";
export const query = graphql`
  query {
    heart: file(relativePath: { eq: "corporate/corporate__heart--teal-square.png" })
    {
      childImageSharp {
        fluid(
            maxWidth: 2000
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
   }`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Container = makeShortcode("Container");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEO title="Corporate" mdxType="SEO" />
    <HeroBanner image={{
      "src": props.data.heart.childImageSharp.fluid.src,
      "alt": "MedGrocer"
    }} color="light" mdxType="HeroBanner">
      <h1 className="title is-size-1-desktop is-size-2-tablet mt-0 pb-1">Where innovation meets real impact</h1>
      <p className="subtitle is-size-7-tablet is-size-7-mobile">
        <p>{`Working with hundreds of the top Philippine companies and multinational drug manufacturers, we serve thousands of patients everyday.`}</p>
        <p>{`We go beyond just delivering medicines. We deliver holistic healthcare solutions: from analytics, to customized programs, to end-to-end fulfillment.`}</p>
      </p>
    </HeroBanner>
    <Hero mdxType="Hero">
      <Container isCentered desktop={10} fullhd={10} className="mt-5-desktop mt-5-fullhd" mdxType="Container">
        <div className="content has-text-centered pb-2">
          <h1>{`What we can do`}</h1>
          <p>{`MedGrocer's platform saves time and money while enabling better service and smarter choices`}</p>
        </div>
        <div className="columns is-centered content has-text-centered">
          <MediaBox title="Company Clinics" image={{
            src: clinics,
            alt: "Medicines"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Management`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Medicines and Supplies`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Mental Health`}</p>
              </li>
            </ul>
          </MediaBox>
          <MediaBox title="Employee Pharmacy Benefits" image={{
            src: employeeBenefits,
            alt: "Employee Pharmacy Benefits"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Online Ordering and Delivery`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Vaccination Drives`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Administration`}</p>
              </li>
            </ul>
          </MediaBox>
          <MediaBox title="Patient Support Programs" image={{
            src: patientSupport,
            alt: "Patient Support Programs"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Online Platforms`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Adherence and Compliance`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Design and Analytics`}</p>
              </li>
            </ul>
          </MediaBox>
        </div>
      </Container>
    </Hero>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      